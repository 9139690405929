$w1800: "2000px";
$breakPoints: (
  xs: (
    start: null,
    end: "449px",
  ),
  sm: (
    start: "450px",
    end: "599px",
  ),
  md: (
    start: "600px",
    end: "1023px",
  ),
  lg: (
    start: "1024px",
    end: "1799px",
  ),
  xl: (
    start: "1800px",
    end: null,
  ),
  smallMobile: (
    start: null,
    end: "375px",
  ),
  mobile: (
    start: null,
    end: "599px",
  ),
  smallTablet: (
    start: null,
    end: "790px",
  ),
  tablet: (
    start: null,
    end: "1024px",
  ),
  largeTablet: (
    start: null,
    end: "1199px",
  ),
  laptop: (
    start: null,
    end: "1400px",
  ),
  middleLaptop: (
    start: null,
    end: "1500px",
  ),
  largeLaptop: (
    start: null,
    end: "1620px",
  ),
  extraLaptop: (
    start: null,
    end: "1900px",
  ),
);

@mixin media($breakpoint) {
  $value: map-get($breakPoints, $breakpoint);
  @if $value != null {
    $start: map-get($value, start);
    $end: map-get($value, end);

    @if $start == null {
      @media (max-width: $end) {
        @content;
      }
    } @else if $end == null {
      @media (min-width: $start) {
        @content;
      }
    } @else {
      @media (min-width: $start) and (max-width: $end) {
        @content;
      }
    }
  } @else {
    @warn "Invalid breakpoint `#{$breakpoint}`.";
  }
}
