// /* ./src/index.css */
// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "variables";
@import "media";

a.titlelink {
  @apply no-underline transition duration-300 ease-in-out shadow-titlelink;
}

.Toastify__toast-container {
  font-size: 14px;
}

a.titlelink:hover {
  @apply shadow-titlehover;
}

html {
  font-size: calc(5.5px + 0.315vw);

  @include media(mobile) {
    font-size: calc(5px + 1.55vw);
  }
}

body,
html {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: $main-color-dark;
  overflow-x: hidden;
}

.czx-outer {
  margin: 0 auto;
  width: 100vw;
  max-width: 1900px;
  position: relative;
  // padding: 0 50px;

  .left-shadow {
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, #151615 0%, rgba(21, 22, 21, 0) 100%);

    @include media(mobile) {
      display: none;
    }
  }

  .right-shadow {
    width: 100px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, #151615 0%, rgba(21, 22, 21, 0) 100%);
    transform: rotate(180deg);

    @include media(mobile) {
      display: none;
    }
  }
}

.czx-container {
  margin: 0 auto;
  width: 100vw;
  max-width: 1400px;
  padding: 0 20px;
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;

  > div {
    width: 100%;
  }

  @include media(smallTablet) {
    padding: 0 50px;
  }

  @include media(mobile) {
    padding: 0 24px;
    box-sizing: border-box;
  }
}

a {
  color: #fff;
  text-decoration: none;
}

.czx-main-green {
  color: $main-color-green !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2 {
  &.with-line {
    position: relative;

    &::before {
      content: "";
      display: flex;
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      height: 4px;
      background-color: $main-color-green;
      width: 64px;

      @include media(mobile) {
        top: -10px;
      }
    }
  }
}

h1 {
  @include media(mobile) {
    font-size: 28px !important;
    line-height: initial !important;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input,
button {
  outline: none;
  border: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.action--connect {
  background-color: $main-color-green;
  color: #fff;

  &:hover {
    background-color: #198564;
  }
}

.custom-sumsub {
  width: 100%;
  padding-top: 60px;
}

// CUSTOM SCROLLBAR
::-webkit-scrollbar {
  width: 5px;
  overflow: auto;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21cf9a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #127a5b;
}
// CUSTOM SCROLLBAR END
